$font-size: 18.2px;
$text-color: #565656;

.loadingSpinner {
  width: 50px;
  height: 50px;
  display: block;
  margin-left: auto;
  margin-right: auto;

  -webkit-animation:spin 4s linear infinite;
  -moz-animation:spin 4s linear infinite;
  animation:spin 4s linear infinite;
}

.redirectScreen {
  max-width: 500px;
  text-align: center;
  margin: 0 auto;
  padding: 50px;
}

.paragraph {
  font-size: $font-size;
  color: $text-color;
  margin-top: 30px;
}


@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
