@import url("https://resource.manz.at/fonts/Roboto/roboto.css");

$light-grey: #f0f0f0;
$grey: #b5b5b5;
$dark-grey: #696969;
$red: #ae130f;
$border-top-width: 6px;
$padding: 3px;
$base-font-size: 15px;

.bridge {
  box-sizing: border-box;
  background-color:white;
  border-bottom: 1px solid $light-grey;
  color: black;
  font-family: 'Roboto', 'Arial', 'sans-serif';
  font-size: $base-font-size;
  float: left;
  padding-top: $padding + $border-top-width;
  padding-bottom: $padding;
  padding-left: 0;
  padding-right: 0;
  overflow: auto;
  width: 100%;

  &:before {
    background: $red;
    position: fixed;
    z-index: 1000000;
    width: 100%;
    content: " ";
    height: $border-top-width;
    top: 0;
    left: 0;
  }

  span {
    color: $grey;
    align-items: center;
    display: inline-flex;
  }

  .iconBridge {
    align-items: center;
    display: inline-flex;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    
    &.iconRed {
      display: none;
    }
  }

  a {
    align-items: center;
    color: $dark-grey;
    display: inline-flex;

    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: $red;

      .iconGrey {
        display: none;
      }
      .iconRed {
        display:inline-flex;
      }
    }
  }
}

.bridgeContent {
  float: right;
  display: flex;
  align-items: center;
  height: 24px;
  margin: 0 30px 0 0;
  li {
    margin-left: 15px;
    display: inline-flex;
    list-style-type: none;
    padding: 0;
  }

  @media screen and (max-width: 992px) {
    margin-right: 15px;
  }
}
